export default function Footer() {
  return (
    <>
      <footer className="flex justify-center gap-2 prose-sm text-[#434955]">
        <span>
          &copy; {new Date().getFullYear()} <i className="fa-solid fa-heart"></i>{" "}
          <a href="/">Verein für Fanfarenmusik Bachra e. V.</a>
        </span>
        {" "}|{" "}
        <a href={"/impressum"}>Impressum</a>
      </footer>
    </>
  );
}
